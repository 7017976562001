import {inject, Injectable} from '@angular/core';
import {TokenDTO} from "../models/TokenDTO";
import { Observable, retry, catchError, throwError } from 'rxjs';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  urlBase = 'http://localhost:8080/session';

  private httpClient: HttpClient = inject(HttpClient);

  login(userName: string, password: string): Observable<TokenDTO> {
    return this.httpClient.post<TokenDTO>(this.urlBase + `/login`, {userName, password})
      .pipe(
        retry(2),
        catchError(this.handleError))
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {

      errorMessage = error.error.message;

    } else {

      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;

    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };
}
